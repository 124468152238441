import React from 'react'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'

import BiomarkerInformationView from './BiomarkerInformation.view'

export interface OnVoucherSuccessParams {
  onErrorCallback: Function
  onSuccessCallback: Function
  values: any
}

export interface BiomarkerInformationProps {
  defaultValues: any
  loading: boolean
  onSuccess: (params: OnVoucherSuccessParams) => void
}

type BiomarkerInformationFullProps = BiomarkerInformationProps & ModalBasicProps

const BiomarkerInformation: React.FC<BiomarkerInformationFullProps> = ({
  defaultValues,
  onHideModal,
}) => (
  <BiomarkerInformationView
    defaultValues={defaultValues}
    loading={false}
    onHideModal={onHideModal}
  />
)

export default BiomarkerInformation
