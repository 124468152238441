import React, { useState } from 'react'

import { VoucherError } from '@probatix/common/services/toolkit/order/orderService.model'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'

import LoginModalView from './LoginModal.view'

export interface LoginModalForm {
  control: any,
  onOpen: any,
  register: any,
  scrollTop: boolean,
}

export interface OnLoginParams {
  onErrorCallback: Function
  onSuccessCallback: Function
  values: LoginModalForm
}

export interface LoginModalContainerProps {
  defaultValues: LoginModalForm
  loading: boolean
  onSuccess: (params: OnLoginParams) => void
}

type LoginModalContainerFullProps = LoginModalContainerProps & ModalBasicProps

const LoginModalContainer: React.FC<LoginModalContainerFullProps> = ({
  defaultValues,
  loading,
  onHideModal,
  onSuccess,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = (values: LoginModalForm, setFieldErr: Function) => {
    setIsSubmitting(true)

    onSuccess({
      onErrorCallback: (res: VoucherError) => {
        setIsSubmitting(false)
        setFieldErr('code', { message: res.data!.detail! })
      },
      onSuccessCallback: () => {
        setIsSubmitting(false)
        onHideModal()
      },
      values,
    })
  }

  return (
    <LoginModalView
      defaultValues={defaultValues}
      isSubmitting={isSubmitting}
      loading={loading}
      onHideModal={onHideModal}
      onSubmit={handleSubmit}
    />
  )
}

export default LoginModalContainer
