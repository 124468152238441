import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { COLORS } from '@probatix/common/constants/colors'
import { TITLE_H2_SIZE } from '@probatix/common/constants/design'

import {
  StyledBodyContainer,
  StyledDialogContent,
  StyledDialogCrossButton,
  StyledDialogOverlay,
} from "./CustomDialog.styled"

import Button from '../Button'
import Grid from '../Grid'
import Space from '../Space'
import Typography from '../Typography'

const CustomDialog = ({
  children,
  hideCrossButton,
  onToggleChange,
  open,
  showTitleOnDialog,
  title,
}: any) => (
  <Dialog.Root
    defaultOpen={true}
    open={open}
    onOpenChange={() => onToggleChange()}
  >
    <Dialog.Portal>
      <StyledDialogOverlay />
      <StyledDialogContent>
        <Grid gridTemplateColumns="auto auto">
          <Grid.Item>
            {
              !!showTitleOnDialog && <Typography
                fontSize={TITLE_H2_SIZE}
                fontWeight={600}
              >
                {title}
              </Typography>
            }
          </Grid.Item>
          <Grid.Item justifyContent='right'>
            <StyledDialogCrossButton>
              {!hideCrossButton && (
                <React.Fragment>
                  <Button
                    icon="close"
                    iconColor={COLORS.BLACK}
                    iconSize={25}
                    label=""
                    padding="0"
                    textColor={COLORS.BLACK}
                    version="transparent"
                    onClick={() => {
                      onToggleChange()
                    }}
                  />
                  <Space space='10px' />
                </React.Fragment>)}
            </StyledDialogCrossButton>
          </Grid.Item>
        </Grid>
        <StyledBodyContainer>
          {children}
        </StyledBodyContainer>
      </StyledDialogContent>
    </Dialog.Portal>
  </Dialog.Root>
)

export default CustomDialog