
import React, { useEffect, useRef, useState } from 'react'
import { Drawer } from 'vaul'
import {
  StyledDrawerContent,
  StyledDrawerOverlay,
  StyledDrawerPortal,
  StyledDrawerContentContainer,
  StyledDialogTitle,
  StyledDrawerHandle,
} from './CustomDrawer.styled'

const CustomDrawer = ({ children, isOpen, onToggle, scrollTop, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [snapPoints, setSnapPoints] = useState([1])
  const [isSet, setIsSet] = useState<boolean>(false)
  const snap = 1

  useEffect(() => {
    if (!isSet) {
      if (ref?.current?.offsetHeight && window.innerHeight * 0.8 < ref.current.offsetHeight) {
        setSnapPoints([0.75, 1])
      }
      else if (ref?.current?.offsetHeight && window.innerHeight * 0.5 < ref.current.offsetHeight) {
        setSnapPoints([0.5, 1])
      }
      setIsSet(true)
    }
  }, [ref, children])

  const updateBodyTop = () => {
    if (scrollTop) {
      document.body.style.top = '0px'
    }
  }

  useEffect(() => {
    const root = document.getElementById("root")
    const checkoutHeader = document.getElementById("checkout-header")

    if (!root) return

    if (isOpen) {
      scrollTop && checkoutHeader && (checkoutHeader!.style!.top = '0px')
      scrollTop && window.scrollTo(0, 0)
      updateBodyTop()
      root.setAttribute("vaul-drawer-wrapper", "")
      document.body.style.cssText = ''
      document.body.style.background = 'green'
      document.body.style.overflow = 'hidden'
      // updateBodyTop()
      root.style.overflow = 'hidden'
    }

    return () => {
      document.body.removeAttribute("vaul-drawer-wrapper")
      scrollTop && checkoutHeader && (checkoutHeader!.style!.top = '0px')
      // document.body.style.top = ''
      root.removeAttribute("vaul-drawer-wrapper")
      // document.body.style.overflow = ''
      document.body.style.cssText = ''
      root.style.cssText = ''
    }

  }, [isOpen, scrollTop])

  return (
    <Drawer.Root
      activeSnapPoint={snap}
      closeThreshold={0.2}
      fadeFromIndex={0}
      open={isOpen}
      shouldScaleBackground={true}
      snapPoints={snapPoints}
      onOpenChange={onToggle}
    >
      <StyledDrawerPortal>
        <StyledDrawerOverlay />
        <StyledDrawerContent open={isOpen} {...props} aria-describedby={undefined}>
          <StyledDrawerHandle data-vaul-handle-hitarea />
          <StyledDialogTitle />
          <StyledDrawerContentContainer>
            <div ref={ref}>
              {children}
            </div>
          </StyledDrawerContentContainer>
        </StyledDrawerContent>
      </StyledDrawerPortal>
    </Drawer.Root>
  )
}

export default CustomDrawer
