import React, { useState } from 'react'

import { NullableString } from '@probatix/common/models/global'
import { VoucherError } from '@probatix/common/services/toolkit/order/orderService.model'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'

import EnterVoucherView from './EnterVoucher.view'

export interface EnterVoucherForm {
  code: NullableString
}

export interface OnVoucherSuccessParams {
  onErrorCallback: Function
  onSuccessCallback: Function
  values: EnterVoucherForm
}

export interface EnterVoucherContainerProps {
  defaultValues: EnterVoucherForm
  loading: boolean
  onSuccess: (params: OnVoucherSuccessParams) => void
}

type EnterVoucherContainerFullProps = EnterVoucherContainerProps & ModalBasicProps

const EnterVoucherContainer: React.FC<EnterVoucherContainerFullProps> = ({
  defaultValues,
  loading,
  onHideModal,
  onSuccess,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = (values: EnterVoucherForm, setFieldErr: Function) => {
    setIsSubmitting(true)

    onSuccess({
      onErrorCallback: (res: VoucherError) => {
        setIsSubmitting(false)
        setFieldErr('code', { message: res.data!.detail! })
      },
      onSuccessCallback: () => {
        setIsSubmitting(false)
        onHideModal()
      },
      values,
    })
  }

  return (
    <EnterVoucherView
      defaultValues={defaultValues}
      isSubmitting={isSubmitting}
      loading={loading}
      onHideModal={onHideModal}
      onSubmit={handleSubmit}
    />
  )
}

export default EnterVoucherContainer
