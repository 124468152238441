import styled from 'styled-components'
import color from 'color'
import { Drawer } from 'vaul'

import { Title } from '@radix-ui/react-dialog'

import { COLORS } from '@probatix/common/constants/colors'

import { DRAWER_BORDER_RADIUS } from '@probatix/myprobatix/src/constants'

export const StyledDrawerRoot = styled(Drawer.Root)``

export const StyledDrawerContentContainer = styled.div`
  width: 100%;                     
  margin-left: auto; 
  margin-right: auto;
  overflow: auto; 
  padding: 2rem 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const StyledDrawerPortal = styled(Drawer.Portal)``

export const StyledDrawerOverlay = styled(Drawer.Overlay)`
  background-color: ${color(COLORS.BLACK).alpha(0.6)};
  inset: 0;
  position: fixed;
`

export const StyledDrawerContent = styled(Drawer.Content)`
  bottom: 0;                       
  left: 0;                        
  right: 0;     
  max-height: calc(80vh); 
  display: flex;                  
  flex-direction: column;         
  position: fixed;    
  
  border: 1px solid ${COLORS.WHITE}; 
  border-width: 0px;
  border-top-right-radius: ${DRAWER_BORDER_RADIUS};
  border-top-left-radius : ${DRAWER_BORDER_RADIUS};
  background-color: ${COLORS.WHITE}; 

  &:focus-visible {
    border: 0px;
    outline: 0px;
  } 
`

export const StyledDrawerHandle = styled(Drawer.Handle)`
  background: rgb(212 212 216);
`

export const StyledDialogTitle = styled(Title)`
  display: none;
`
