import { Nullable } from '@probatix/common/models/global'

// TODO: remove additional user in the end of task
import { Customer } from '@probatix/common/services/RTKServices/patients/index.model'
import { Location } from '@probatix/common/services/RTKServices/locations/index.model'
import { GeoLocation } from '@probatix/common/services/toolkit/geoLocation/geoLocationService.model'
import {
  EligibilitySelfConfirmationTypes,
  Product,
} from '@probatix/common/services/RTKServices/checkoutSteps/checkoutStepsService.model'

export type TWrapperCheckoutPage = {
  baseProductUuid?: string,
  location?: string,
  nextStep: (type) => void,
  onSortUnGroupedLocations?: any,
  product?: string,
  renderBackFooter?: () => JSX.Element,
  renderFooter?: () => JSX.Element,
  unGroupedLocations?: Location[]
}

export type TWrapperLocationStep = TWrapperCheckoutPage & {
  isQuickOrder: boolean,
  navigateToTestAtHome: () => void,
  onSortByLocation: () => void,
  setDefaultLocation: () => void
}

export const enum FlowType {
  FULL = 'FULL',
  LOCATION = 'LOCATION',
  MAIN = 'MAIN',
  PRODUCT = 'PRODUCT',
  QUICK_CHECKOUT = 'QUICK_CHECKOUT'
}

export interface TestsFormServiceState {
  baseProduct?: Nullable<Product>,
  checkedProduct: boolean,
  crossOutPrice: boolean,
  eligibilitySelfConfirmationType?: EligibilitySelfConfirmationTypes,
  enabledTest?: boolean,
  explode: boolean,
  flow: FlowType,
  location: Nullable<Location>,
  person: Nullable<Customer>,
  product: Nullable<Product>,
  publicCheckout: boolean,
  store?: Nullable<GeoLocation>,
  time: Nullable<Date>
}

export interface TestsServiceState {
  form: TestsFormServiceState
}

export interface TestsServiceRootState {
  tests: TestsServiceState
}

export interface SubmissionError {
  additionalPersonIndexes: number[]
  main: boolean
}
