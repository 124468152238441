import styled from 'styled-components'

import React from 'react'

import { noop } from '@probatix/common/constants/global'

import { ALIGN_OPTIONS, VARIANT_TAGS, TypographyPropsBasic } from './Typography.model'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const StyledTypography = styled(({
  align,
  background,
  clamp,
  color,
  cursor,
  ellipsis,
  firstLine,
  fontFamily,
  fontSize,
  fontWeight,
  hoverColor,
  hoverOpacity,
  inline,
  italic,
  letterSpacing,
  linkColorInherit,
  margin,
  maxWidth,
  nowrap,
  opacity,
  overflow,
  padding,
  prewrap,
  textAlign,
  textDecoration,
  textShadow,
  transform,
  underline,
  userSelect,
  variant,
  width,
  wordBreak,
  ...props
  /* eslint-enable @typescript-eslint/no-unused-vars */
}) => (
  <p {...props} />
)) <TypographyPropsBasic>`
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  -webkit-font-smoothing: auto;
  font-synthesis: none;

  ${({ background }) => background && `
    background: ${background};
  `}

  ${({ width }) => width && `
    width: ${width};
  `}


  ${({ maxWidth }) => maxWidth && `
    max-width: ${maxWidth};
  `}

  ${({ fontFamily }) => fontFamily && `
    font-family: ${fontFamily};
  `}
  
  ${({ inline }) => inline && `
    display: inline;
  `}
  
  ${({ linkColorInherit }) => linkColorInherit && `
    & > a {
      color: inherit;
    }
  `}

  ${({ letterSpacing }) => letterSpacing && `
    letter-spacing: ${letterSpacing};
  `}
  
  p {
    margin: 0;
    line-height: inherit;
  }
  
  ${({ fontWeight }) => fontWeight && `
    font-weight: ${fontWeight};
  `}
  
  ${({ color }) => color && `
    color: ${color};
  `}

  ${({ margin }) => margin && `
    margin: ${margin};
  `}

  ${({ padding }) => padding && `
    padding: ${padding};
  `}

  ${({ align }) => ALIGN_OPTIONS.LEFT === align && `
    text-align: left;
  `}
  
  ${({ align }) => ALIGN_OPTIONS.CENTER === align && `
    text-align: center;
  `}
  
  ${({ align }) => ALIGN_OPTIONS.RIGHT === align && `
    text-align: right;
  `}  

  ${({ align }) => ALIGN_OPTIONS.JUSTIFY === align && `
    text-align: justify;
  `}
  
  ${({ prewrap }) => prewrap && `
    white-space: pre-wrap;
  `}

  ${({ nowrap }) => nowrap && `
    white-space: nowrap;
  `}
  
  ${({ onClick }) => onClick && onClick !== noop && `
    cursor: pointer;
  `}  

  ${({ cursor }) => cursor && `
    cursor: ${cursor};
  `}
  
  ${({ transform }) => transform && `
    text-transform: ${transform};
  `}

  ${({ variant }) => VARIANT_TAGS.H1 === variant && `
    font-size: 46px;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.H2 === variant && `
    font-size: 38px;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.H3 === variant && `
    font-size: 32px;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.H4 === variant && `
    font-size: 26px;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.H5 === variant && `
    font-size: 0.875rem;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.H6 === variant && `
    font-size: 18px;
    font-weight: 600;
  `}
  
  ${({ variant }) => VARIANT_TAGS.SMALL === variant && `
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.875rem;
  `}
  
  ${({ variant }) => VARIANT_TAGS.SPAN === variant && `
    display: inline-block;
    width: auto;
  `}
  
  ${({ fontSize }) => fontSize && 'inherit' !== fontSize && `
    font-size: ${fontSize};
    
    & ${StyledTypography} {
      font-size: ${fontSize};
    }
  `}
  
  ${({ fontSize }) => fontSize && 'inherit' === fontSize && `
    font-size: inherit;
  `}
  
  ${({ firstLine, lineHeight }) => lineHeight && !firstLine && `
    line-height: ${lineHeight};
  `}
  
  ${({ firstLine, lineHeight }) => lineHeight && firstLine && `
    :first-line {
      line-height: ${lineHeight};
    }
  `}

  ${({ wordBreak }) => wordBreak && `
    word-break: ${wordBreak};
  `}
  
  ${({ italic }) => italic && `
    font-style: italic;
  `}

  ${({ opacity }) => opacity && `
    opacity: ${opacity};
  `}

  ${({ ellipsis }) => ellipsis && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${({ clamp }) => clamp && `
    white-space: normal;
    -webkit-line-clamp: ${clamp};
    display: -webkit-box;
    -webkit-box-orient: vertical;
  `}


  ${({ overflow }) => overflow && `
    overflow: ${overflow};
  `}

  ${({ underline }) => underline && `
    text-decoration: underline;
  `}
  
  ${({ userSelect }) => userSelect && `
    user-select: ${userSelect};
  `}
  
  ${({ textDecoration }) => textDecoration && `
    text-decoration: ${textDecoration};
  `}

  ${({ textShadow }) => textShadow && `
    text-shadow: ${textShadow};
  `}
    
  ${({ hoverColor }) => hoverColor && `
    transition: .2s;
  
    &:hover {
      color: ${hoverColor};
    }
  `}

  ${({ hoverOpacity }) => hoverOpacity && `
    transition: .2s;
  
    &:hover {
      opacity: ${hoverOpacity};
    }
  `}
`
