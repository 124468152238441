import React from 'react'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'

import BasicModalView from './BasicModal.view'

export interface OnVoucherSuccessParams {
  onErrorCallback: Function
  onSuccessCallback: Function
  values: any
}

export interface BasicModalContainerProps {
  defaultValues: any
  loading: boolean
  onSuccess: (params: OnVoucherSuccessParams) => void
}

type BasicModalContainerFullProps = BasicModalContainerProps & ModalBasicProps

const BasicModalContainer: React.FC<BasicModalContainerFullProps> = ({
  defaultValues,
  loading,
  onHideModal,
}) => (
  <BasicModalView
    defaultValues={defaultValues}
    loading={loading}
    onHideModal={onHideModal} />
)

export default BasicModalContainer
