
import type { Middleware } from '@reduxjs/toolkit'

const tenantIsNotExistMiddleware: Middleware = () => (next) => async (action) => {
  const { type } = action

  if (!type.includes('list/translationsFailed')) {
    return next(action)
  }

  window.location.href = 'https://probatix.de'

  return next(action)
}

export default tenantIsNotExistMiddleware
