import React, { useState } from 'react'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'
import { BiomarkersModal } from '@probatix/common/components/molecules'

type BiomarkerModalViewProps = ModalBasicProps & {
  defaultValues: any
  loading: boolean,
  onHideModal: any,
}

const BiomarkerModalView: React.FC<BiomarkerModalViewProps> = ({
  defaultValues,
  onHideModal,
}) => {
  const [open, setOpen] = useState(true)

  const closeModal = () => {
    setOpen(false)
    onHideModal()
  }

  return (defaultValues && defaultValues.biomarkers &&
    <BiomarkersModal
      biomarkers={defaultValues.biomarkers}
      noLine={defaultValues.noLine || false}
      open={open}
      productName={defaultValues.productName || ""}
      onClose={closeModal}
    />
  )
}

export default BiomarkerModalView
