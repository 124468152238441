
import React, { useState } from 'react'

import { ModalBasicProps } from '@probatix/myprobatix/src/core/shell/components/ModalRoot/ModalRoot'
import { CustomModal } from '@probatix/common/components/molecules'

import { Trans } from '@probatix/common/translations'

import { LoginModalForm } from './LoginModal.container'
import { Typography } from '@probatix/common/components/atoms'
import { TITLE_H1_WEIGHT } from '@probatix/common/constants/design'

type LoginModalViewProps = ModalBasicProps & {
  defaultValues: LoginModalForm
  isSubmitting: boolean
  loading: boolean
  onSubmit: (values: LoginModalForm, setFieldErr: Function) => void
}

const LoginModalView: React.FC<LoginModalViewProps> = ({
  defaultValues,
  onHideModal,
}) => {
  const [open, setOpen] = useState(true)

  const closeModal = () => {
    setOpen(false)
    onHideModal()
  }

  return (defaultValues ?
    <CustomModal
      hideCrossButton={false}
      isActiveModal={open}
      isOpen={open}
      scrollTop={defaultValues.scrollTop}
      title={
        <Typography
          fontWeight={TITLE_H1_WEIGHT}
          padding="0px 0px 0px 40px"
        >
          <Trans k="modals:LoginModal:title" />
        </Typography>}
      showTitleOnDialog
      onClose={closeModal}
    >
      {defaultValues.onOpen}
    </CustomModal>
    : <React.Fragment />
  )
}

export default LoginModalView
