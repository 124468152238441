import { configureStore } from '@reduxjs/toolkit'

import { createReduxEnhancer } from '@sentry/react'
import { API_ENTRYPOINT, ENV } from 'config/app'

import apiServiceMiddleware from '@probatix/common/services/toolkit/api/apiService.middleware'
import loginMiddleware from '@probatix/common/middlewares/login.middleware'
import logoutMiddleware from '@probatix/common/middlewares/logout.middleware'
import locationsApiMiddleware from '@probatix/common/services/RTKServices/locations/index.middleware'

import i18n from 'translations'

import reducers from './reducers'

import setAccountUsersMiddleware from './middlewares/setAccountUsers.middleware'
import testsFormPersistMiddleware from './middlewares/testsFormPersist.middleware'
import tenantIsNotExistMiddleware from './middlewares/tenantIsNotExist.middleware'

const localEnvs = ['local',
  'local_with_api',
  'local_on_net',
]

const appReducer = reducers

const rootReducer = (state, action) => appReducer(state, action)

const middlewares = [
  apiServiceMiddleware({
    apiPrefix: '/api/v2/checkout',
    entrypoint: API_ENTRYPOINT,
    i18n,
    withRelativeUrl: !localEnvs.includes(ENV),
    withSubdomain: localEnvs.includes(ENV),
  }),
  loginMiddleware(),
  logoutMiddleware(),
  setAccountUsersMiddleware,
  testsFormPersistMiddleware,
  locationsApiMiddleware,
  tenantIsNotExistMiddleware,
]

const sentryReduxEnhancer = createReduxEnhancer({})

export default configureStore({
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers.concat(sentryReduxEnhancer),
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
  ),
  reducer: rootReducer,
})
